import React, { useState } from "react";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";

const Certificates = () => {
  const lang = "en";

  const [activeDepartment, setActiveDepartment] = useState("1");
  const [openPopup, setOpenPopup] = useState(false);
  const [popupImage, setPopupImage] = useState("");

  const openPopupHandler = (image) => {
    setOpenPopup(true);
    setPopupImage(image);
  };

  const closePopupHandler = () => {
    setOpenPopup(false);
    setPopupImage("");
  };

  return (
    <Layout
      seoTitle="Certificates"
      lang={lang}
      translationPL="/certyfikaty/"
      translationDE="/de/zertifikate/"
    >
      {/* eslint-disable jsx-a11y/control-has-associated-label */}
      <SmallBanner title="Certificates" lang={lang} />
      {openPopup ? (
        <div className="certificates__popup">
          <button
            onClick={() => closePopupHandler()}
            className="popup__background"
          />
          <div className="popup__content">
            <img className="popup__image" src={popupImage} alt="Certyfikat" />
            <button
              className="popup__close"
              onClick={() => closePopupHandler()}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <section className="certificates">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="certificates__tab-nav mb-50">
                <button
                  onClick={() => setActiveDepartment("1")}
                  className={`btn btn--plain btn--center ${
                    activeDepartment === "1" ? "current" : ""
                  } `}
                >
                  PalettenWerk
                </button>
                <button
                  onClick={() => setActiveDepartment("2")}
                  className={`btn btn--plain btn--center ${
                    activeDepartment === "2" ? "current" : ""
                  } `}
                >
                  Palko
                </button>
              </div>
            </div>
            <div className="col-12">
              {activeDepartment === "1" ? (
                <>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(
                          require("../../assets/img/pwCert1.jpg")
                        )
                      }
                      style={{
                        backgroundImage: `url(${require(`../../assets/img/pwCert1.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">
                        ISO 9001:2015 quality certificate
                      </h3>
                      <p className="xs mb-20">
                        We have held an ISO quality certificate since 2005. The
                        procedures and instructions we have developed optimise
                        all the processes carried out in the company, from the
                        supply of raw materials to production and sales. These
                        processes are adapted to the specific nature of our
                        industry. The annual audits for this certification prove
                        that our procedures and instructions comply with ISO
                        practices.
                      </p>
                      <img
                        className="item__logo"
                        src={require("../../assets/img/iso.jpg")}
                        alt="ISO"
                      />
                      <a
                        href={require("../../assets/img/pwCert1.pdf")}
                        download
                        className="item__btn btn"
                        data-hover="Download"
                      >
                        Download
                        <span className="btn-arrows"></span>
                      </a>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(require("../../assets/img/ISO14001.jpg"))
                      }
                      style={{
                        backgroundImage: `url(${require(`../../assets/img/ISO14001.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">Environmental Certificate ISO 14001:2015
                      </h3>
                      <p className="xs mb-20">
                      We have a certificate attesting that the environmental management 
                      system implemented by the organization complies with the ISO 14001:2015
                      standard and covers the organizational structure, planning, allocation
                      of responsibility and resources needed to develop, implement and conduct
                      management in a manner that takes into account current environmental
                      problems. The system is appropriately adapted to the specifics 
                      of our industry. Annual audits as part of the obtained certificate 
                      confirm the compliance of the entire enterprise management system 
                      with the standard.
                      </p>
                      <div className="item__content__actions">
                        <a
                          className="item__btn btn"
                          href={require("../../assets/img/ISO14001.pdf")}
                          data-hover="Download"
                          download
                        >
                          Download
                          <span className="btn-arrows"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(
                          require("../../assets/img/pwCert2.jpg")
                        )
                      }
                      style={{
                        backgroundImage: `url(${require(`../../assets/img/pwCert2.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">ISPM15 certificate (IPPC)</h3>
                      <p className="xs mb-20">
                        We were one of the first companies to receive a
                        certificate confirming our technological capability to
                        heat treat pallets according to the ISPM15 standard,
                        which allows us to send goods on our pallets outside the
                        European Union and to all countries that require such
                        treatment (e.g. Ukraine, Norway, or the UK). This
                        process prevents insects from migrating to new
                        ecosystems.
                      </p>
                      <img
                        className="item__logo"
                        src={require("../../assets/img/itd.png")}
                        alt="ITD"
                      />
                      <a
                        className="item__btn btn"
                        href={require("../../assets/img/pwCert2.pdf")}
                        data-hover="Download"
                        download
                      >
                        Download
                        <span className="btn-arrows"></span>
                      </a>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(
                          require("../../assets/img/pwCert3.jpg")
                        )
                      }
                      style={{
                        backgroundImage: `url(${require(`../../assets/img/pwCert3.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">PEFC certificate</h3>
                      <p className="xs mb-20">
                        The PEFC certificate attests to the fact that the wood
                        used by our company comes from a legal source and also
                        ensures that forests are replenished after the trees are
                        felled and used for industrial purposes. Our company
                        monitors the incoming raw wood material and thus
                        operates on a sustainable, responsible, environmentally
                        friendly raw material management policy.
                      </p>
                      <a
                        className="item__btn btn"
                        href={require("../../assets/img/pwCert3.pdf")}
                        data-hover="Download"
                        download
                      >
                        Download
                        <span className="btn-arrows"></span>
                      </a>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(
                          require("../../assets/img/pwCert4.jpg")
                        )
                      }
                      style={{
                        backgroundImage: `url(${require(`../../assets/img/pwCert4.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">EPAL licence</h3>
                      <p className="xs mb-20">
                        We were one of the first companies in Poland to obtain a
                        licence for the production of pallets according to the
                        EPAL standard in 1998; we were also a co-founder of the
                        Polish National Committee of EPAL. This licence
                        guarantees the quality of manufacturing of 1200x800 cm
                        pallets in accordance with the standard developed by the
                        UIC. The quality of pallets is checked several times a
                        month by an independent inspection body. This ensures
                        unrestricted exchange of pallets and enables them to be
                        sold all over the world. We manufacture all types of
                        EPAL pallets.
                      </p>
                      <img
                        className="item__logo"
                        src={require("../../assets/img/quality-sign.png")}
                        alt="EPAL"
                      />
                      <a
                        className="item__btn btn"
                        href={require("../../assets/img/pwCert4.pdf")}
                        data-hover="Download"
                        download
                      >
                        Download
                        <span className="btn-arrows"></span>
                      </a>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(
                          require("../../assets/img/pwCert5.jpg")
                        )
                      }
                      style={{
                        backgroundImage: `url(${require(`../../assets/img/pwCert5.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">AEO certificate</h3>
                      <p className="xs mb-20">
                        From the very beginning of our business, we have been
                        the largest Polish exporter of pallets to Europe and
                        beyond. Thanks to our reputation, we were allowed to
                        make simplified export clearances, which greatly
                        shortened and optimised the shipment of our goods
                        abroad.
                      </p>
                      <img
                        className="item__logo"
                        src={require("../../assets/img/AEO.png")}
                        alt="AEO"
                      />
                      <a
                        className="item__btn btn"
                        href={require("../../assets/img/pwCert5.pdf")}
                        data-hover="Download"
                        download
                      >
                        Download
                        <span className="btn-arrows"></span>
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {activeDepartment === "2" ? (
                <>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(
                          require("../../assets/img/palkoCert1.jpg")
                        )
                      }
                      style={{
                        backgroundImage: `url(${require(`../../assets/img/palkoCert1.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">
                        ISO 9001:2015 quality certificate
                      </h3>
                      <p className="xs mb-20">
                        We have held an ISO quality certificate since 2005. The
                        procedures and instructions we have developed optimise
                        all the processes carried out in the company, from the
                        supply of raw materials to production and sales. These
                        processes are adapted to the specific nature of our
                        industry. The annual audits for this certification prove
                        that our procedures and instructions comply with ISO
                        practices.
                      </p>
                      <img
                        className="item__logo"
                        src={require("../../assets/img/iso.jpg")}
                        alt="ISO"
                      />
                      <a
                        className="item__btn btn"
                        href={require("../../assets/img/palkoCert1.pdf")}
                        data-hover="Download"
                        download
                      >
                        Download
                        <span className="btn-arrows"></span>
                      </a>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(
                          require("../../assets/img/ispm15_certificate.jpg")
                        )
                      }
                      style={{
                        backgroundImage: `url(${require(`../../assets/img/ispm15_certificate.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">ISPM15 certificate (IPPC)</h3>
                      <p className="xs mb-20">
                        We were one of the first companies to receive a
                        certificate confirming our technological capability to
                        heat treat pallets according to the ISPM15 standard,
                        which allows us to send goods on our pallets outside the
                        European Union and to all countries that require such
                        treatment (e.g. Ukraine, Norway, or the UK). This
                        process prevents insects from migrating to new
                        ecosystems.
                      </p>
                      <img
                        className="item__logo"
                        src={require("../../assets/img/itd.png")}
                        alt="ITD"
                      />
                      <a
                        className="item__btn btn"
                        href={require("../../assets/img/ispm15_certificate.pdf")}
                        data-hover="Download"
                        download
                      >
                        Download
                        <span className="btn-arrows"></span>
                      </a>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(
                          require("../../assets/img/pwCert3.jpg")
                        )
                      }
                      style={{
                        backgroundImage: `url(${require(`../../assets/img/pwCert3.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">PEFC certificate</h3>
                      <p className="xs mb-20">
                        The PEFC certificate attests to the fact that the wood
                        used by our company comes from a legal source and also
                        ensures that forests are replenished after the trees are
                        felled and used for industrial purposes. Our company
                        monitors the incoming raw wood material and thus
                        operates on a sustainable, responsible, environmentally
                        friendly raw material management policy.
                      </p>
                      <a
                        className="item__btn btn"
                        href={require("../../assets/img/palkoCert3.pdf")}
                        data-hover="Download"
                        download
                      >
                        Download
                        <span className="btn-arrows"></span>
                      </a>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(
                          require("../../assets/img/palkoCert4.jpg")
                        )
                      }
                      style={{
                        backgroundImage: `url(${require(`../../assets/img/palkoCert4.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">EPAL licence</h3>
                      <p className="xs mb-20">
                        We were one of the first companies in Poland to obtain a
                        licence for the production of pallets according to the
                        EPAL standard in 1998; we were also a co-founder of the
                        Polish National Committee of EPAL. This licence
                        guarantees the quality of manufacturing of 1200x800 cm
                        pallets in accordance with the standard developed by the
                        UIC. The quality of pallets is checked several times a
                        month by an independent inspection body. This ensures
                        unrestricted exchange of pallets and enables them to be
                        sold all over the world. We manufacture all types of
                        EPAL pallets.
                      </p>
                      <img
                        className="item__logo"
                        src={require("../../assets/img/quality-sign.png")}
                        alt="EPAL"
                      />
                      <a
                        className="item__btn btn"
                        href={require("../../assets/img/palkoCert4.pdf")}
                        data-hover="Download"
                        download
                      >
                        Download
                        <span className="btn-arrows"></span>
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Certificates;
